<template>
	<ul class="tabs">
		<li
			class="tabs-item"
			:class="{'tabs-item-selected': value === id}"
			v-for="{id, label} in items"
			:key="id"
			@click="$emit('input', id)"
		>
			{{label}}
		</li>
	</ul>
</template>

<script>
export default {
	name: 'Tabs',

	props: {
		value: {
			type: String,
			required: true
		},
		items: {
			type: Array,
			required: true
		}
	}
};
</script>

<style lang="scss">
@import '@/scss/_colors.scss';

.tabs {
    display: flex;
    align-items: center;
    &-item {
        padding: 0 16px;
        line-height: 42px;
        border-left: 1px solid $light-gray-03;
        font-family: 'Optimistic Display', 'Noto Sans Khmer', sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: $meta-blue;
        cursor: pointer;
        &-selected {
            color: $blue-100;
            background-color: $blue-600;
        }
        &:last-of-type {
            border-right: 1px solid $light-gray-03;
        }
    }
}
</style>
