<template>
	<div class="content saved-filter-selections-tab">
		<v-container class="pa-0">
			<v-row class="pl-6 pr-6">
				<v-text-field
					label="Search"
					color="blueGray"
					class="search-input"
					v-model="search"
				></v-text-field>
			</v-row>
			<v-row>
				<v-col
					v-for="(selection, selectionIdx) in filteredSelections"
					:key="selectionIdx"
					cols="sm"
					lg="4"
					sm="6"
					:class="$vuetify.breakpoint.mdAndDown ? 'pa-3' : 'pa-6'"
				>
					<v-card
						outlined
						:elevation="selection.focused ? '3' : '0'"
						@mouseover="selection.focused = true"
						@mouseleave="selection.focused = false"
						class="favorite-card"
					>
						<div class="card-selection favorite-card-primary">
							<div
								v-for="(entry, entryIdx) in entries"
								:key="entryIdx"
								class="card-selection-entry"
							>
								<div class="card-selection-entry-key">{{entry.label}}:</div>
								<div class="card-selection-entry-value">{{formatList(selection[entry.id])}}</div>
							</div>
						</div>

						<v-divider></v-divider>

						<div class="card-subtext favorite-card-secondary">
							<div class="card-subtext-title">
								<div class="card-subtext-title-main">
									{{selection.title}}
								</div>
								<div class="card-subtext-title-info">
									{{selection.date}} at {{selection.time}}
								</div>
							</div>

							<v-btn icon>
								<i
									class="icon-UI-experience-close icon-rounded icon-red"
									aria-hidden="true"
									@click.stop="confirmRemoval(selection)"
								></i>
							</v-btn>

							<v-btn icon>
								<i
									class="icon-UI-experience-arrow-next icon-rounded"
									aria-hidden="true"
									@click="openSelection(selection)"
								></i>
							</v-btn>
						</div>
					</v-card>
				</v-col>

				<DialogRemoveFavorite
					:showDialog="dialog"
					:message="dialogMessage"
					@cancel="onCancel"
					@remove="onRemove"
				/>
			</v-row>
		</v-container>
	</div>
</template>

<script>
const { mapMutations, mapActions } = require('vuex');
const moment = require('moment');
const api = require('@/utils/api');
const { STEPS } = require('@/store/general');

import DialogRemoveFavorite from '@/components/DialogRemoveFavorite';

export default {
	name: 'SavedFilterSelectionsTab',

	components: {
		DialogRemoveFavorite
	},

	data () {
		return {
			search: '',
			entries: [
				{ id: 'survey', label: 'Survey' },
				{ id: 'markets', label: 'Markets' },
				{ id: 'respondents', label: 'Repondents' },
				{ id: 'topics', label: 'Topics' }
			],
			selections: [],
			selectionToRemove: {},
			dialog: false
		};
	},

	computed: {
		dialogMessage () {
			return 'Are you sure you want to remove from your favorites the filter selection ' +
				`"<strong>${this.selectionToRemove.title}</strong>" ?`;
		},
		filteredSelections () {
			return this.selections.filter((item) => {
				const { title, date, time, survey, markets, respondents, topics } = item;
				return [title, date, time, survey, markets, respondents, topics]
					.flat()
					.some(this.matchesSearch);
			});
		}
	},

	created () {
		this.loadSavedFilterSelections();
	},

	methods: {
		...mapMutations('general', [
			'changeStep'
		]),
		...mapMutations('talkingPoints', [
			'setInitialFilters'
		]),
		...mapActions('talkingPoints', [
			'selectSurvey'
		]),
		matchesSearch (string) {
			return string.toUpperCase().includes(this.search.toUpperCase());
		},
		formatList (array) {
			if (!Array.isArray(array)) return array;
			return array.map((elt) => elt.label).join(', ');
		},
		async loadSavedFilterSelections () {
			const data = await api.loadSavedFilterSelections();
			if (data?.savedFilterSelections) {
				this.selections = data.savedFilterSelections.map((selection) => {
					const localDate = moment(selection.date);
					return {
						...selection,
						date: localDate.format('YYYY-MM-DD'),
						time: localDate.format('h:mm A'),
						focused: false
					};
				});
			}
		},
		confirmRemoval (selection) {
			this.selectionToRemove = selection;
			this.dialog = true;
		},
		onCancel () {
			this.dialog = false;
		},
		onRemove () {
			this.dialog = false;
			this.deleteSelection(this.selectionToRemove.id);
		},
		async deleteSelection (selectionId) {
			const result = await api.deleteFilterSelection(selectionId);
			if (result) {
				const selectionIndex = this.selections.findIndex((selection) => selection.id = selectionId);
				if (selectionIndex !== 1) this.selections.splice(selectionIndex, 1);
			}
		},
		openSelection (selection) {
			const { surveyId, markets, respondents, topics } = selection;
			this.setInitialFilters({ markets, respondents, topics });
			this.selectSurvey(surveyId);
			this.changeStep(STEPS.TALKING_POINTS);
		}
	}
};
</script>

<style lang="scss">
@import '@/scss/colors.scss';

.saved-filter-selections-tab {
    padding: 16px 8px;

    .search-input {
        max-width: 320px;
    }

    .card-selection {
        padding: 16px 24px;
        background-color: $blue-100;

        &-entry {
            display: flex;
            line-height: 18px;
            font-size: 14px;
            font-family: 'Optimistic Display', 'Noto Sans Khmer', sans-serif;
            color: $meta-blue;
            margin-bottom: 8px;
            &:last-of-type {
                margin-bottom: 0;
            }
            &-key {
                flex: 1;
                font-weight: bold;
            }
            &-value {
                flex: 2;
                width: 100%;
                height: 36px;

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                @supports (-webkit-line-clamp: 2) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: initial;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}
</style>
