<template>
	<div class="favorites">
		<header class="page-header">
			<div class="_container">
				<span class="page-title">Saved filters and favorite surveys</span>
				<router-link to='/'>
					<span class="page-header-close material-icons-outlined">close</span>
				</router-link>
			</div>
		</header>

		<nav class="page-nav">
			<div class="_container">
				<Tabs
					v-model="selectedTab"
					:items="tabItems"
					@input="$router.push({ path: '/favorites' , query: {tab: selectedTab}})"
				/>
			</div>
		</nav>

		<div class="_container">
			<keep-alive>
				<SavedSurveysTab v-if="selectedTab === 'surveys'"/>
				<SavedFilterSelectionsTab v-else/>
			</keep-alive>
		</div>
	</div>
</template>

<script>
import Tabs from '@/components/Tabs';
import SavedSurveysTab from '@/components/SavedSurveysTab';
import SavedFilterSelectionsTab from '@/components/SavedFilterSelectionsTab';

export default {
	name: 'Favorites',

	components: {
		Tabs,
		SavedSurveysTab,
		SavedFilterSelectionsTab
	},

	data () {
		return {
			selectedTab: '',
			tabItems: [
				{
					id: 'surveys',
					label: 'Favorite Surveys'
				},
				{
					id: 'filter-selections',
					label: 'Saved Filter Selections'
				}
			]
		};
	},

	computed: {
		targetTab () {
			return this.$route.query.tab;
		},
		fullPath () {
			if (this.targetTab) {
				return `${this.$route.path}?tab=${this.targetTab}`;
			}
			return this.$route.path;
		}
	},

	created () {
		this.selectedTab = this.targetTab || this.tabItems[0].id;
	},

	watch: {
		$route () {
			this.selectedTab = this.targetTab || this.tabItems[0].id;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";
@import '@/scss/variables.scss';

.page-title {
    font-family: 'Optimistic Display', 'Noto Sans Khmer', sans-serif;
    font-size: 22px;
    font-weight: normal;
    color: $meta-blue;
}

.page-header-close {
  color: $meta-gray;
}

.page-nav {
    border-top: 1px solid $light-gray-03;
    border-bottom: 1px solid $light-gray-03;
    margin: 0 -32px;
    padding: 0 32px;
    background-color: $blue-100;
    ._container {
        display: flex;
        align-items: center;
    }
}

.page-header {
    margin: 0 -32px;
    padding: 32px 40px;
    background-color: $blue-100;
    ._container {
        display: flex;
        align-items: center;
        a {
            margin-left: auto;
        }
    }
}

.favorites {
  margin-top: $header-height;
	padding: 0 32px;
}
</style>
