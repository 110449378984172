<template>
	<div class="content saved-surveys-tab">
		<v-container class="pa-0">
			<v-row class="pl-6 pr-6">
				<v-text-field
					label="Search"
					color="metaGray"
					class="search-input"
					v-model="search"
				></v-text-field>
			</v-row>
			<v-row>
				<v-col
					v-for="(survey, key) in filteredSurveys"
					:key="key"
					cols="sm"
					lg="4"
					sm="6"
					:class="$vuetify.breakpoint.mdAndDown ? 'pa-3' : 'pa-6'"
				>
					<v-card
						outlined
						:elevation="survey.focused ? '3' : '0'"
						@mouseover="survey.focused = true"
						@mouseleave="survey.focused = false"
						class="favorite-card"
					>
						<div class="card-survey favorite-card-primary">
							<div class="card-survey-overline">Favorite survey</div>
							<div class="card-survey-title">{{survey.title}}</div>
						</div>

						<v-divider></v-divider>

						<div class="card-subtext favorite-card-secondary">
							<div class="card-subtext-title">
								<div class="card-subtext-title-main">
									{{survey.title}}
								</div>
								<div class="card-subtext-title-info">
									{{survey.date}} |
									<span class="card-subtext-title-info-type">Survey</span>
								</div>
							</div>

							<v-btn icon>
								<i
									class="icon-UI-experience-close icon-rounded icon-red"
									aria-hidden="true"
									@click.stop="confirmRemoval(survey)"
								></i>
							</v-btn>

							<v-btn icon>
								<i
									class="icon-UI-experience-arrow-next icon-rounded"
									aria-hidden="true"
									@click="openSurvey(survey.id)"
								></i>
							</v-btn>
						</div>
					</v-card>
				</v-col>

				<DialogRemoveFavorite
					:showDialog="dialog"
					:message="dialogMessage"
					@cancel="onCancel"
					@remove="onRemove"
				/>
			</v-row>
		</v-container>
	</div>
</template>

<script>
const { mapMutations, mapActions } = require('vuex');
const api = require('@/utils/api');
import { STEPS } from '@/store/general';

import DialogRemoveFavorite from '@/components/DialogRemoveFavorite';

export default {
	name: 'SavedSurveysTab',

	components: {
		DialogRemoveFavorite
	},

	data () {
		return {
			search: '',
			surveys: [],
			surveyToRemove: {},
			dialog: false
		};
	},

	computed: {
		dialogMessage () {
			return 'Are you sure you want to remove from your favorites the survey ' +
				`"<strong>${this.surveyToRemove.title} (${this.surveyToRemove.date})</strong>" ?`;
		},
		filteredSurveys () {
			return this.surveys.filter((survey) =>
				survey.title.toUpperCase().includes(this.search.toUpperCase()) ||
				survey.date.includes(this.search)
			);
		}
	},

	created () {
		this.loadSavedSurveys();
	},

	methods: {
		...mapMutations('general', [
			'changeStep'
		]),
		...mapMutations('talkingPoints', [
			'setInitialFilters'
		]),
		...mapActions('talkingPoints', [
			'selectSurvey'
		]),
		async loadSavedSurveys () {
			const result = await api.loadSavedSurveys();
			if (result?.savedSurveys) {
				this.surveys = result.savedSurveys.map((survey) => ({ ...survey, focused: false }));
			}
		},
		async unsaveSurvey (surveyId) {
			const result = await api.unsaveSurvey(surveyId);

			if (!result) return;

			const surveyIdx = this.surveys.findIndex((survey) => survey.id === surveyId);
			if (surveyIdx !== -1) {
				this.surveys = this.surveys.slice(0, surveyIdx).concat(this.surveys.slice(surveyIdx + 1));
			}
		},
		confirmRemoval (survey) {
			this.surveyToRemove = survey;
			this.dialog = true;
		},
		onRemove () {
			this.dialog = false;
			this.unsaveSurvey(this.surveyToRemove.id);
		},
		onCancel () {
			this.dialog = false;
		},
		openSurvey (surveyId) {
			this.setInitialFilters({});
			this.selectSurvey(surveyId);
			this.changeStep(STEPS.TALKING_POINTS);
		}
	}
};
</script>

<style lang="scss">
@import "@/scss/_colors.scss";

.saved-surveys-tab {
    padding: 16px 8px;

    &-actions {
        padding-left: 8px;
        padding-right: 8px;
    }

    .search-input {
        max-width: 320px;
    }

    .card-survey {
        padding: 16px 24px;
        background-color: $blue-100;
        min-height: 200px;

        &-overline {
            font-size: 14px;
            font-weight: bold;
            color: $blue-300;
            text-transform: uppercase;
            margin-bottom: 16px;
        }

        &-title {
            font-family: 'Optimistic Display', 'Noto Sans Khmer', sans-serif;
            font-weight: 500;
            font-size: 36px;
            line-height: 42px;
            color: $meta-blue;
        }
    }

    .card-subtext {
        &-title-main {
            font-weight: bold;
        }
	}
}
</style>
